import gql from 'graphql-tag'




export const GET_HOME_CONTENT = gql`
  query GetHomeContent {
    studioKast {
      data{
          attributes {
              title
              lead_text
              content
              slider(pagination:{
                  limit:-1
              }){
                  is_video
                  video_url
                  media {
                    data {
                        attributes {
                        name
                        url
                        previewUrl
                        }
                    }
                  }
              }
              more_content {
                  title
                  partners(pagination:{
                    limit:-1
                  }) {
                    name
                    link
                  }
              }
          }
      }
    }
  }
`


export const GET_VISUALS_CONTENT = gql`
    query GetHomeContent {
      threeDVisual {
        data{
            attributes{
                title
                lead_text
                content
                slider(pagination:{
                    limit:-1
                }){
                    is_video
                    video_url
                    media {
                      data {
                          attributes {
                          name
                          url
                          previewUrl
                          }
                      }
                    }
                }
            }
        }
      }
    }
`


export const GET_PRODUCT_CONTENT = gql`
    query GetProductContent {
      produkte {
        data{
            attributes{
                title
                lead_text
                content
                slider(pagination:{
                    limit:-1
                }){
                    is_video
                    video_url
                    media {
                      data {
                          attributes {
                          name
                          url
                          previewUrl
                          }
                      }
                    }
                }
            }
        }
      }
    }
`

export const GET_ABOUT_CONTENT = gql`
    query GetAboutContent {
      about {
        data{
            attributes {
                title
                lead_text
                content
                slider(pagination:{
                    limit:-1
                }){
                    is_video
                    video_url
                    media {
                      data {
                          attributes {
                          name
                          url
                          previewUrl
                          }
                      }
                    }
                }
            }
        }
      }
    }
`

export const GET_IMPRESSUM_CONTENT = gql`
    query GetImpressumContent {
      impressum{
        data{
            attributes{
                list_impressum {
                        title
                        content
                }
            }
        }
      }
    }
`

export const GET_TERM_CONTENT = gql`
    query GetTermContent {
      term {
        data {
            attributes{
                list_terms {
                  title
                  description{
                      title
                      content
                  }
                }
            }
        }
      }
    }
`

export const GET_HERO_CONTENT = gql`
  query GetHeroContent {
    headerSlider {
      data {
        attributes {
          items {
            play_speed
            media_items {
              id
              title
              description
              is_video
              video_url
              invert
              color
              background_color
              more_link
              media {
                data {
                  attributes {
                    name
                    url
                    previewUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`


export const GET_SITE_INFO = gql`
  query GetSiteInfo {
      siteSetting {
          data {
              attributes {
                  site_info {
                     address_1
                     address_2
                     name
                     mail
                     phone
                  }
                  socials {
                    link
                    type
                  }
              }
          }
      }
  }
`

