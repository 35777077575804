
import { ThemeProvider } from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import type { AppProps } from 'next/app'
import '../styles/globals.scss'
import theme from '../styles/theme'
import { SiteProvider  } from '../context/appContext'
import App from "next/app";
import client from "../lib/graphql"
import { GET_HERO_CONTENT } from '../graphql/query'

function MyApp({ Component, pageProps }: AppProps) {
  
  return (
    <ThemeProvider theme={theme}>
      <SiteProvider>
        <AnimatePresence
          exitBeforeEnter
          initial={false}
          onExitComplete={() => window.scrollTo(0, 2000)}
        >
          <Component {...pageProps} />
        </AnimatePresence>
      </SiteProvider>
    </ThemeProvider>
  )
  
}

MyApp.getInitialProps = async (ctx: any) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(ctx);
  // Fetch global site settings from Strapi
  const { data: { headerSlider } } = await client.query({
    query: GET_HERO_CONTENT,
  });
  // Pass the data to our page via props
  return { ...appProps, pageProps: { headerSlider: headerSlider ? headerSlider.data.attributes.items : null } };
};

export default MyApp
