import React, { createContext, useContext, ReactNode, useState } from "react";

interface ISiteContext {
  isMenuOpen: boolean
  isSticky: boolean
}

const defaultContext: ISiteContext = {
  isMenuOpen: false,
  isSticky: false
};

/* eslint-disable */
const SiteContext = createContext<{ context: ISiteContext, setContext: (context: ISiteContext) => void} > ({
    context: defaultContext,
    // @ts-ignore
    setContext: (context: ISiteContext) => {}
});
/* eslint-enable */ 

export function useSiteContext() {
    return useContext(SiteContext);
}

type Props = {
    children: ReactNode;
};

export function SiteProvider({ children }: Props) {
 
    const [currentContext, setCurrentContext] = useState(defaultContext);

    const setContext = (context: ISiteContext) => {
      setCurrentContext({...context});
    }
  

    return (
        <SiteContext.Provider value={{context: currentContext, setContext}}>
            {children}
        </SiteContext.Provider>
    );
}
