const theme = {
  name: "StudioKast",
  fonts: {
    family: {
      primary: "'Overpass', sans-serif"
    },
    weights: {
      normal: '400',
      semiBold: '500',
      bold: '700',
      black: '900'
    }
  },
  colors: {
    textColor: "#000000",
    blackColor: "#000000",
    darkColor: "#003147"
	},
  breakPoints: {
    tablet: '768px',
    desktop: '1024px',
    wide: '1280px',
    large: '1366px',
    hd: '1440px'
  }
}

export default theme;
